<template>
  <div v-if="cart" class="cart">
    <div class="cart-icon" :class="shakeEffect"
      @mouseover="showCart()"
      @click="gotoAccount()">
      <img :src="require('images/cart.svg')" />
      <span v-show="cartCount > 0" class="badge badge-pill badge-warning">{{cartCount}}</span>
      <span v-show="order && cart && cartCount == 0 && orderTotal > 0" class="badge-ok" style="font-size: 1.5em; color: #2A6336;">
        <i class="fas fa-check-circle"></i>
      </span>
    </div>
    <div class="text-center" v-if="cart && cartCount > 0">
      <span class="amount color-red">{{cartTotal | formatAmount}}</span>
      <span class="currency color-red">FCFA</span>
    </div>
    <div class="text-center" v-if="order && cart && cartCount == 0 && orderTotal > 0">
      <span class="amount color-primary">{{orderTotal | formatAmount}}</span>
      <span class="currency color-primary">FCFA</span>
    </div>
    <div class="text-center orderDate" v-if="order && cart && cartCount == 0 && orderTotal > 0">
      <span class="color-primary">{{order.date | formatMonth}}</span>
    </div>
    <transition name="fade" mode="out-in">
      <div v-if="cartCount > 0" v-show="showContent" @mouseleave="hideCart()" class="cart-content">
        <div class="row cart-items">
          <cart-box-item v-for="item in cartBoxes" :key="item.id" :item="item" />
          <cart-item v-for="item in cartProducts" :key="item.id" :item="item" :with-likes="authenticated" :likes="likes" />
        </div>
        <div class="row cart-total border-top border-bottom mt-4 justify-content-center">
          <span class="amount">{{cartTotal | formatAmount}}</span>
          <span class="currency">FCFA</span>
        </div>
        <div class="row py-3 mx-5">
          <a @click="gotoAccount()" class="btn btn-primary btn-lg btn-block">Continuer</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import services from './packs/services'
import eventBus from './packs/eventBus'

export default {
  data: function () {
    return {
      shakeEffect: "",
      items: [],
      likes: [],
      cart: null,
      order: null,
      showContent: false,
      authenticated: false,
      loading: false
    }
  },
  mounted() {
    this.refresh()
    eventBus.$on('OrderNeedValidation', function() {
      this.refresh()
      this.shake()
    }.bind(this))

    eventBus.$on('OrderValidated', function() {
      this.refresh()
    }.bind(this))

    eventBus.$on('OrderUpdated', function() {
      this.refresh()
    }.bind(this))
  },

  computed: {

    unValidated() {
      return this.unValidatedProducts.length > 0
    },

    unValidatedProducts() {
      return this.cart.order_items
    },

    cartProducts() {
      return this.items.filter(item => !item.panier)
    },

    cartBoxes() {
      return this.items.filter(item => item.panier)
    },

    cartCount() {
      return this.items.length
    },

    cartTotal() {
      let total = 0
      this.items.forEach(item => {
        let price = 0
        if (item.panier) {
          price = item.box.price 
        }else {
          price = item.product.price 
        }
        total += item.quantity * price
      });
      return total
    },

    orderTotal() {
      let total = 0
      this.order.order_items.forEach(item => {
        total += item.quantity * item.price
      });
      return total
    },
  },

  methods: {

    showCart() {
      this.showContent = true
    },

    hideCart() {
      this.showContent = false
    },

    async refresh() {
      this.loading = true
      let user = await services.getCurrentUser()
      if(user.id) {
        this.authenticated = true
        this.order = await services.getCartContent()
      }
      this.likes = await services.getLikes()
      this.cart = await services.getTCartContent()
      this.items = this.cart.order_items
      this.loading = false
    },

    shake() {
      this.shakeEffect = "shake-hard shake-constant"
      setTimeout(() => {
        this.shakeEffect = ""
      }, 500)
    },

    gotoAccount() {
      if(this.authenticated){
        if (this.unValidated) {
          window.location.href = '/my-cart'
        } else {
          window.location.href = '/account'
        }
      }else {
        window.location.href = '/cart/recap'
      }  
    }

  }
}
</script>

<style lang='scss' scoped>

.orderDate {
  border-top: solid 1px #2A6336;
  span{
    font-size: 11px;
  }
}

.cart {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
}

.cart-icon {
  cursor: pointer;
  width: 80px;
}

.cart-content {
  padding: 10px 15px;
  position: absolute;
  top: 88px;
  right: 0px;
  width: 400px;
  margin-right: -25px;
  background-color:#F4F3F0;
}

.cart-items {
  max-height: 330px;
  overflow-y: auto;
  margin: 0;
}

.cart-total {
  height: 60px;
  line-height: 60px;
}

.cart-total .amount {
  font-size: 16px;
}
.cart-total .currency {
  font-size: 12px;
  margin-left: 5px;
}

.badge {
  position: absolute;
  right: 6px;
  top: -12px;
  font-size: 13px;
  width: 25px;
  height: 25px;
  line-height: 20px;
}

.badge-warning {
  color: #FFF;
  background-color: #E97834;
}

.badge-ok {
  position: absolute;
  right: 6px;
  top: -12px;
}

.card {
  width: auto;
  height: 102px;
}

.card-body {
  padding: 10px 10px;
}

.card-body-title {
  height: 60px;
}

.card-title {
  margin-bottom: 5px;
}

.card-text-price {
  font-size: 10px;
}

.cart-item {
  margin-bottom: 10px;
  background-color:#fff;
}

.cart-item img {
  width: 100%;
  height: auto;
  min-height: 100px;
  object-fit: cover;
  border-radius: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.amount {
  font-family: 'ITC Souvenir Std Demi';
  font-size: 12px;
}

.currency {
  font-family: 'Montserrat';
  font-size: 10px;
}


</style>
