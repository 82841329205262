import Vue from 'vue/dist/vue.esm'
import moment from 'moment'
import numeral from 'numeral'

numeral.register('locale', 'fr', {
  delimiters: {
      thousands: '.',
      decimal: ','
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  },
  ordinal : function (number) {
      return number === 1 ? 'er' : 'ème';
  },
  currency: {
      symbol: 'FCFA'
  }
});

// switch between locales
numeral.locale('fr');

Vue.filter('formatDate', function (value) {
  if(!value) return ""
  return moment(value).format('DD MMMM YYYY')
})

Vue.filter('formatDay', function (value) {
  if(!value) return ""
  return moment(value).format('dddd')
})

Vue.filter('formatMonth', function (value) {
  if(!value) return ""

  return moment(value).format('DD MMMM')
})

Vue.filter('formatFriendlyDate', function (value) {
  if(!value) return ""
  return moment(value).format('dddd DD MMM')
})

Vue.filter('formatFriendlyDateFull', function (value) {
  if(!value) return ""
  return moment(value).format('dddd DD MMMM')
})

Vue.filter('formatAmount', function (value) {
  return numeral(Math.floor(value)).format('0,0')
})