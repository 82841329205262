<template>
  <div v-if="box" class="container-fluid padding-md margin-top-60 product-detail">
    <div class="row">
      <div class="col-12">
        <nav aria-label="breadcrumb" >
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/#/">Accueil</a></li>
            <li class="breadcrumb-item"><a :href="back.url">{{back.title}}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{box.name}}</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-8">
        <div class="main-img" :style="{backgroundImage: 'url(' + currentImage + ')' }">
          <ribbon :padding="{left: '15px'}" v-if="box.active_tags.new_article"/>
        </div>
      </div>
      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h1>{{box.name}}</h1>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p>{{box.description}}</p>
              </div>
            </div>
            <div class="row order">
              <div class="col-6">
                <button @click="add()" class="btn btn-primary" v-if="is_add_state" >Ajouter</button>
                <div class="label" v-if="is_qte_state">Quantité</div>
                <div class="col-12 px-0 product-qte" v-if="is_qte_state">
                  <button @click="minusOne()" class="btn btn-qte input-qte-minus">-</button>
                  <input :disabled="true" v-model="quantity" class="form-control input-qte" type="text" />
                  <button @click="plusOne()" class="btn btn-qte">+</button>
                </div>
              </div>
              <div class="col-6">
                <div class="label">Prix</div>
                <div class="value">{{box.price}} FCFA</div>
              </div>
            </div>
            <product-frequency
              v-if="!box.isOneShotable && is_qte_state"
              :productId="box.id"
              :oneShot="oneShot"
              :inCart="true"
              :isBox="true"
            ></product-frequency>
          </div>
        </div>
      </div>
    </div>

    <div class="row margin-top-30" v-if="false">
      <div class="col-8 conservation">
        <div class="row">
          <div class="col-2">
            <span>Conservation</span>
          </div>
          <div class="col-2">
            <img class="thumbnail" />
          </div>
          <div class="col-8">
            <p>{{box.description}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="false">
      <div class="col-8 producer">
        <div class="row">
          <div class="col-2">
            <span>Le Producteur</span>
          </div>
          <div class="col-10">
            <div class="title">Nebeday</div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget ultrices libero. Mauris nulla tellus, mattis ac pulvinar sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-10 composition">
        <div class="row">
          <div class="col-12">
            <h1 class="title">Composition</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-3 mr-2 mb-3" v-for="item in box.box_items" :key="item.id">
            <div class="product-item">
              <div class="product-img">
                <img :src="`https://www.clubtiossane.sn/ckossam/${item.product.imgUrl}`" />
              </div>

              <div class="product-body">
                <div class="product-body-desc">
                  <div class="title">
                    {{item.product.name}}
                  </div>
                  <div class="price">   
                    {{item.price | formatAmount}} FCFA
                  </div>
                </div>
                <div class="product-body-actions">
                  <div class="config">
                    <div class="product-qte">
                      <input :disabled="true" v-model="item.quantity" class="form-control input-qte" type="text" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import EventBus from './packs/eventBus'
import service from './packs/services'

export default {
  props: ["boxId", "rubrique", "category"],
  data: function () {
    return {
      status: 'add',
      currentImage: '',
      quantity: 0,
      cart: null,
      box: null,
      liked: false,
      temporary: true
    }
  },
  async mounted() {
    this.box = await service.getBox(this.boxId)
    this.cart = await service.getTCartContent()
    
    //this.currentImage = this.product.images[0]
    this.currentImage = `https://www.clubtiossane.sn/ckossam/${this.box.imgUrl}`
    this.temporary = this.cart.temporary
    if(this.cart.order_items) {
      let ordered = this.cart.order_items.find(p => p.panier && p.box.id == this.box.id)
      if(ordered) {
        this.quantity = ordered.quantity
      }
    }
    this.status = (this.quantity > 0) ? 'set_quantity' : 'add'
  },
  computed: {
    is_add_state(){
      return this.status === 'add'
    },
    
    is_qte_state(){
      return this.status === 'set_quantity'
    },

    back() {
      if (this.rubrique) {
        return {title: this.rubrique.title, url: `/rubrique/${this.rubrique.id}#/`};
      } else if (this.category) {
        return {title: this.category.title, url: `/category/${this.category.id}#/`};
      }
      return {url: '/rubrique/12#/', title: 'Panier'};
    },

    oneShot() {
      if (!this.cart.order_items) return false

      const orderItem = this.cart.order_items.find(p => p.panier && p.box.id === this.box.id)
      if (!orderItem) return

      return orderItem.oneShot
    }
  },
  methods: {
    
    async add(){
      this.status = 'set_quantity'
      await this.plusOne()
    },

    selectImage(img) {
      this.currentImage = img
    },

    async plusOne() {
      this.quantity++;
      await service.setBoxQuantity(this.box.id, this.quantity)
      EventBus.$emit("OrderNeedValidation", {})
    },

    async minusOne() {
      if(this.quantity > 0) {
        this.quantity--;
        await service.setBoxQuantity(this.box.id, this.quantity)
        EventBus.$emit("OrderNeedValidation", {})
        if(this.quantity == 0){
          this.status = 'add'
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>

.product-item {
  background-color: #fff;
  height: 13vh;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  .product-img {
    width: 30%;
    display: block;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .product-body {
    width: 70%;
    display: flex;
    flex-direction: row;
    .product-body-desc {
      width: 60%;
      padding: 10px 10px;
      .title {
        font-family: Recoleta;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
      }
      .price {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
      }
    }
    .product-body-actions {
      width: 40%;
      padding: 10px 10px;
      .icons {
        text-align: right;
      }
      .config {
        height: 100%;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .product-qte {
          display: inline-flex;
        }
      }
    }
  }
}

.composition {
  padding-top: 25px;
  .title {
    margin-bottom: 20px;
  }
}


.product-detail .card {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 25px 25px;
}

.product-detail .badge {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 15px;
  text-transform: uppercase;
  color: #fff;
  background: #E97834;
  border-radius: 5px;
}

.product-detail h1 {
  font-family: 'Recoleta';
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
}

.product-detail .card-body .row {
  margin-bottom: 20px;
}

.product-detail .card-body p {
  font-family: Montserrat;
  font-style: normal;
  font-size: 14px;
}

.product-detail {
  .main-img {
    width: 100%;
    height: 60vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
  .label {
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    padding: 10px 5px;
  }

  .value {
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}

.product-detail .thumbnail {
  width: 110px;
  height: 110px;
  margin-right: 10px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.product-detail .conservation {
  border-top: 1px solid #BDBDBD;
  border-bottom: 1px solid #BDBDBD;
  padding-top: 20px;
  padding-bottom: 20px;
}

.conservation p,
.producer p {
  font-family: Montserrat;
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
}

.product-detail .producer {
  padding-top: 20px;
  padding-bottom: 20px;
}

.producer .title {
  font-family: 'ITC Souvenir Std Demi';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.product-th-image {
  cursor: pointer;
}

.product-qte {
  margin-top: 5px;
  display: inline-flex;
}

.btn-qte {
  width: 30px;
  height: 30px;
  padding: 5px 2px;
  line-height: 10px;
  font-family: Recoleta;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: #2A6336;
  border: 1px solid #2A6336;
  border-radius: 4px;
}

.input-qte:disabled, .input-qte[readonly] {
  background-color: #fff;
}

.input-qte {
  width: 30px;
  height: 30px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #757575;
  padding: 8px 0px;
  text-align: center;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.input-qte-minus {
  padding-top: 6px;
}

.card-text-price {
  margin-bottom: 0px;
}

.select {
  padding: 5px;
  background-color: #fff;
  color: #2A6336;
  border-radius: 8px;
  height: 35px;
  font-family: Montserrat;
  font-size: 12px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.select.active {
  color: #fff;
  background-color: #2A6336;
}

</style>
