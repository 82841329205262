<template>
  <div v-if="product" class="order-item-container">
    <div class="product-item">
      <div class="product-img flex-shrink-0">
        <div class="aspect-container"></div>
        <a>
          <img :src="product.imgUrl" />
        </a>
      </div>

      <div class="d-flex flex-column flex-grow-1 h-100">
        <div class="product-body">
          <div class="product-body-desc">
            <div class="title">
              <a>{{ product.name }}</a>
            </div>
            <a class="price">
              <span>{{product.price | formatAmount}} FCFA</span>
            </a>
          </div>
        </div>
        <div class="product-footer">
          <div class="config">
            <div class="product-qte mr-1">
              <input :disabled="true" v-model="product.quantity" class="form-control input-qte" type="text" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import bottleImg from 'images/bottle.jpeg'
import potImg from 'images/pot.jpeg'

export default {
  props: ['glassId', 'quantity'],
  data() {
    return {
      product: null
    }
  },

  async mounted(){
    this.init()
  },

  computed: {
  },

  methods: {

    init() {
      if(this.glassId == 1) {
        this.product = {
          id: this.glassId,
          name: 'Consigne de bouteille en verre',
          imgUrl: bottleImg,
          quantity: this.quantity,
          price: 1500
        }
      }
      if(this.glassId == 2) {
        this.product = {
          id: this.glassId,
          name: 'Consigne de pot en verre',
          imgUrl: potImg,
          quantity: this.quantity,
          price: 500
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  color: inherit;
  text-decoration: none;
}

.product-item {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 100%;

  .product-img {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    display: block;
    position: relative;
    overflow: hidden;
    .aspect-container {
      padding-bottom: 56.25%;
      width: 100%;
    }
    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .img-icons {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 10px;
      top: 10px;
      .imgc {
        display: block;
        height: auto;
        margin-bottom: 10px;
        width: 1.6rem;
      }
    }
  }
  .product-body {
    min-height: 8vh;
    display: flex;
    flex-direction: row;
    .product-body-desc {
      padding: 10px 0px 10px 10px;
      .title {
        font-family: Recoleta;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
      }
      .price {
        display: block;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        white-space: nowrap;
        width: 100%;

        &.discount {
          span {
            text-decoration: line-through;
          }
          strong {
            display: inline;
          }
        }

        strong {
          background: rgba(42, 99, 54, 0.08);
          border-radius: 3px;
          color: #2a6336;
          display: none;
          font-weight: 900;
          margin-left: 3px;
          padding: 2px 4px;
          white-space: nowrap;
        }
      }
    }
    .product-body-actions {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      padding: 10px 10px;
      .icons {
        height: 30%;
        text-align: right;
      }
      .favorite-button {
        cursor: pointer;
      }
    }
  }
  .product-footer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 3px 10px 7px 10px;
    margin: auto 0;

    .config {
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .product-qte {
        display: inline-flex;
      }
    }

    .config, .product-frequency {
      margin-bottom: 0.5rem;
    }
  }
  .ic-close {
    position: absolute;
    cursor: pointer;
    right: -5px;
    top: -10px;
    img {
      width: 20px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: row;
    min-height: 13vh;
    width: 100%;

    .product-img {
      border-radius: 0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      height: auto;
      width: 35%;
    }
  }
}

.card-body-title {
  height: 60px;
}

.card-title {
  margin-bottom: 5px;
}

.card-text-price {
  font-size: 10px;
  margin-bottom: 3px;
}

.cart-item {
  margin-bottom: 10px;
  background-color:#fff;
}

.cart-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.btn-qte {
  width: 30px;
  height: 30px;
  padding: 5px 2px;
  line-height: 10px;
  font-family: Recoleta;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: #2A6336;
  border: 1px solid #2A6336;
  border-radius: 4px;
}

.input-qte:disabled, .input-qte[readonly] {
  background-color: #fff;
}

.input-qte {
  width: 30px;
  height: 30px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #757575;
  padding: 8px 0px;
  text-align: center;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.input-qte-minus {
  padding-top: 6px;
}

.select {
  margin-left: 5px;
  padding: 5px;
  background-color: #fff;
  color: #2A6336;
  border-radius: 4px;
  height: 25px;
  font-family: Montserrat;
  font-size: 10px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.select.active {
  color: #fff;
  background-color: #2A6336;
}

.text-flag {
  color: #D6403F;
  height: 35px;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 12px;
  padding: 7px 15px;
}
</style>
