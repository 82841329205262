<template>
  <div class="product-item">
    <div class="product-img flex-shrink-0">
      <div class="aspect-container"></div>
      <a :href="pagePath">
        <div class="img-icons">
          <span v-show="!isBox && !product.orderableUntil21H">
            <img title="Attention!! Produit à commander au moins 24h en avance." class="imgc" :src="require('images/24_hours.svg')" />
          </span>
          <span class="imgc" v-show="product.biologique">
            <img :src="require('images/ic_bio.svg')" />
          </span>
          <span class="imgc" v-show="product.local">
            <img :src="require('images/ic_local.svg')" />
          </span>
          <span class="imgc" v-show="product.frais">
            <img :src="require('images/ic_frais.svg')" />
          </span>
        </div>
        <img :src="`https://www.clubtiossane.sn/ckossam/${product.imgUrl}`" />
      </a>
    </div>

    <ribbon
      :size="{height: '15vh', width: '15vh'}"
      :span="{fontSize: '1.5vh'}"
      v-if="product.active_tags && product.active_tags.new_article"
    />

    <div class="d-flex flex-column flex-grow-1 h-100">
      <div class="product-body">
        <div class="product-body-desc">
          <div class="title">
            <a :href="pagePath">{{ product.name }}</a>
          </div>
          <a :href="pagePath" :class="`${hasDiscount && showDiscount ? 'discount' : ''} price`">
            <span>{{product.price | formatAmount}} FCFA</span>
            <strong :v-if="hasDiscount">{{product.price | formatAmount}} FCFA</strong>
          </a>
        </div>
        <div class="product-body-actions">
          <div class="icons">
            <a class="favorite-button" @click="changeLike()" v-if="withLikes">
              <span class="ic-md" v-show="liked" style="font-size: 1.0em; color: #D6403F;">
                <i class="fas fa-heart"></i>
              </span>
              <span class="ic-md" v-show="!liked" style="font-size: 1.0em; color: #D6403F;">
                <i class="far fa-heart"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="product-footer">
        <div class="config" v-if="product.orderable || isBox">
          <button
            @click="setCanAdd(true)"
            class="btn btn-montserrat btn-sm btn-primary"
            v-if="showAddToggle && !canAdd"
          >Ajouter</button>
          <div class="product-qte mr-1" v-if="canAdd">
            <button v-if="!readOnly" @click="minusOne()" class="btn btn-qte input-qte-minus">-</button>
            <input :disabled="true" v-model="quantity" class="form-control input-qte" type="text" />
            <button v-if="!readOnly" @click="plusOne()" class="btn btn-qte">+</button>
          </div>
        </div>
         <product-frequency
           v-if="showFreq && canAdd"
           :productId="product.id"
           :oneShot="oneShot"
           :inCart="inCart"
           :isBox="isBox"
         ></product-frequency>
        <div class="config" v-if="!product.orderable && !isBox" >
          <span class="text-flag">Epuisé</span>
        </div>
      </div>
    </div>
    <span v-if="canDelete" @click="deleteProduct()" class="ic-close">
      <img :src="require('images/cancel.svg')"/>
    </span>
  </div>
</template>

<script>
import { PRODUCT_STATUS } from './packs/constants'
import eventBus from './packs/eventBus'
import service from './packs/services'

export default {
  props: [
   'initQuantity', 'likes', 'oneShot', 'isBox', 'inCart', 'product', 'showFreq', 'status', 'withLikes',
  ],

  data() {
    return {
      liked: false,
      quantity: 0,
      readOnly: this.status === PRODUCT_STATUS.readonly,
      canAdd: this.status !== PRODUCT_STATUS.shelf,
      canDelete: this.status === PRODUCT_STATUS.validated,
      hasDiscount: this.product.hasDiscount,
      showDiscount: this.status === PRODUCT_STATUS.shelf,
      showAddToggle: this.status === PRODUCT_STATUS.shelf,
      pagePath: this.isBox ? `/box/${this.product.id}` : `/product/${this.product.id}`,
    }
  },

  watch: {
    likes: function(likes) {
      this.checkLikes(likes)
    },
    quantity: function(amount) {
      if (amount === 0) this.setCanAdd(false)
    }
  },

  async mounted() {
    this.quantity = this.initQuantity ?? 0

    this.checkLikes(this.likes)
  },

  methods: {
    async setCanAdd(value){
      this.canAdd = value
      if (value) await this.plusOne()
    },
    async changeLike() {
      this.liked = !this.liked;
      await service.setLike(this.product.id, this.liked)
    },

    checkLikes(likes) {
      if (!likes?.length) return

      const hasLiked = !!likes?.find(
        l => l.liked && l.Product_id === this.product.id
      )
      this.liked = hasLiked
    },

    async deleteProduct() {
      await service.deleteProduct(this.product.id)
      eventBus.$emit("ProductDeleted", {})
    },

    async setValidatedQuantity() {
      if (this.isBox) {
        await service.setOrderBoxQuantity(this.product.id, this.quantity)
      } else {
        await service.setQuantity(this.product.id, this.quantity)
      }

      eventBus.$emit("OrderUpdated", {})
    },

    async setShelfQuantity() {
      if (this.isBox) {
        await service.setBoxQuantity(this.product.id, this.quantity)
      } else {
        await service.setCartQuantity(this.product.id, this.quantity)
      }

      eventBus.$emit("OrderNeedValidation", {})
    },

    async setCartQuantity() {
      if (this.isBox) {
        await service.setBoxQuantity(this.product.id, this.quantity)
      } else {
        await service.setCartQuantity(this.product.id, this.quantity)
      }

      eventBus.$emit("OrderNeedValidation", {})
    },

    async deleteProduct() {
      if (this.isBox) {
        await service.deleteBox(this.product.id)
      } else {
        await service.deleteProduct(this.product.id)
      }

      eventBus.$emit("ProductDeleted", {})
    },

    async plusOne() {
      this.quantity++;
      try {
        switch (this.status) {
          case PRODUCT_STATUS.validated:
            await this.setValidatedQuantity()
            break
          case PRODUCT_STATUS.shelf:
            await this.setShelfQuantity()
            break
          case PRODUCT_STATUS.cart:
            await this.setCartQuantity()
            break
          default:
            break
        }

      } catch (err) {
        this.quantity--;
        if (err.response.status !== 422) return

        if (err.response.data.code == 'ERRBLOCKED') {
          this.$swal({
            icon: 'warning',
            cancelButtonText: 'OK',
            cancelButtonColor: '#2A6336',
            title: "Oups!",
            text: "Vous ne pouvez pas augmenter de quantité car le produit est en rupture de stock",
            showCancelButton: true,
            showConfirmButton: false
          });
        } else if (err.response.data.code == 'ERR21H') {
          this.$swal({
            icon: 'warning',
            cancelButtonText: 'OK',
            cancelButtonColor: '#2A6336',
            title: "Oups!",
            text: "Vous ne pouvez pas augmenté la quantité de ce produit car il doit etre commandé 24h à l'avance",
            showCancelButton: true,
            showConfirmButton: false
          });
        }
      }
    },

    async minusOne() {
      if (this.quantity === 0) return

      this.quantity--;
      switch (this.status) {
        case PRODUCT_STATUS.validated:
          await this.setValidatedQuantity()
          break
        case PRODUCT_STATUS.shelf:
          await this.setShelfQuantity()
          break
        case PRODUCT_STATUS.cart:
          await this.setCartQuantity()
          break
        default:
          break
      }
    },
  }
}
</script>

<style lang="scss" scoped>
a {
  color: inherit;
  text-decoration: none;
}

.product-item {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 100%;

  .product-img {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    display: block;
    position: relative;
    overflow: hidden;
    .aspect-container {
      padding-bottom: 56.25%;
      width: 100%;
    }
    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .img-icons {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 10px;
      top: 10px;
      .imgc {
        display: block;
        height: auto;
        margin-bottom: 10px;
        width: 1.6rem;
      }
    }
  }
  .product-body {
    min-height: 8vh;
    display: flex;
    flex-direction: row;
    .product-body-desc {
      padding: 10px 0px 10px 10px;
      .title {
        font-family: Recoleta;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
      }
      .price {
        display: block;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        white-space: nowrap;
        width: 100%;

        &.discount {
          span {
            text-decoration: line-through;
          }
          strong {
            display: inline;
          }
        }

        strong {
          background: rgba(42, 99, 54, 0.08);
          border-radius: 3px;
          color: #2a6336;
          display: none;
          font-weight: 900;
          margin-left: 3px;
          padding: 2px 4px;
          white-space: nowrap;
        }
      }
    }
    .product-body-actions {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      padding: 10px 10px;
      .icons {
        height: 30%;
        text-align: right;
      }
      .favorite-button {
        cursor: pointer;
      }
    }
  }
  .product-footer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 3px 10px 7px 10px;
    margin: auto 0;

    .config {
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .product-qte {
        display: inline-flex;
      }
    }

    .config, .product-frequency {
      margin-bottom: 0.5rem;
    }
  }
  .ic-close {
    position: absolute;
    cursor: pointer;
    right: -5px;
    top: -10px;
    img {
      width: 20px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: row;
    min-height: 13vh;
    width: 100%;

    .product-img {
      border-radius: 0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      height: auto;
      width: 35%;
    }
  }
}

.card-body-title {
  height: 60px;
}

.card-title {
  margin-bottom: 5px;
}

.card-text-price {
  font-size: 10px;
  margin-bottom: 3px;
}

.cart-item {
  margin-bottom: 10px;
  background-color:#fff;
}

.cart-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.btn-qte {
  width: 30px;
  height: 30px;
  padding: 5px 2px;
  line-height: 10px;
  font-family: Recoleta;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: #2A6336;
  border: 1px solid #2A6336;
  border-radius: 4px;
}

.input-qte:disabled, .input-qte[readonly] {
  background-color: #fff;
}

.input-qte {
  width: 30px;
  height: 30px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #757575;
  padding: 8px 0px;
  text-align: center;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.input-qte-minus {
  padding-top: 6px;
}

.select {
  margin-left: 5px;
  padding: 5px;
  background-color: #fff;
  color: #2A6336;
  border-radius: 4px;
  height: 25px;
  font-family: Montserrat;
  font-size: 10px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.select.active {
  color: #fff;
  background-color: #2A6336;
}

.text-flag {
  color: #D6403F;
  height: 35px;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 12px;
  padding: 7px 15px;
}
</style>
