// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("@fortawesome/fontawesome-free/js/all")

import 'bootstrap'
import 'bootstrap-show-password'

// Moment settings
import moment from 'moment'
moment.locale('fr')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router'
import './filters'

import Multiselect from 'vue-multiselect'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { ToggleButton } from 'vue-js-toggle-button'
import VueSlickCarousel from 'vue-slick-carousel'
import PrettyCheck from 'pretty-checkbox-vue/check';
import VueSweetalert2 from 'vue-sweetalert2';

import App from '../app.vue'
import ProductList from '../ProductList.vue'
import ProductItem from '../ProductItem.vue'
import BoxList from '../BoxList.vue'
import BoxItem from '../BoxItem.vue'
import BoxDetail from '../BoxDetail.vue'
import ProductDetail from '../ProductDetail.vue'
import OrderItemList from '../OrderItemList.vue'
import OrderItem from '../OrderItem.vue'
import OrderBoxItem from '../OrderBoxItem.vue'
import GlassItem from '../GlassItem.vue'
import ReadOnlyOrderItemList from '../ReadOnlyOrderItemList.vue'
import ReadOnlyOrderItem from '../ReadOnlyOrderItem.vue'
import ReadOnlyOrderBoxItem from '../ReadOnlyOrderBoxItem.vue'
import MyAccount from '../MyAccount.vue'
import DeliverySettings from '../DeliverySettings.vue'
import AccountSettings from '../AccountSettings.vue'
import Feedback from '../Feedback.vue'
import NextDelivery from '../NextDelivery.vue'
import MyCart from '../MyCart.vue'
import Notification from '../Notifications.vue'
import LikedProducts from '../LikedProducts.vue'
import Faq from '../Faq.vue'
import ProductFilter from '../ProductFilter.vue'
import LocateMe from '../LocateMe.vue'
import Signup from '../Signup.vue'
import SignupComplete from '../SignupComplete.vue'
import Contact from '../Contact.vue'
import Cart from '../Cart.vue'
import CartItem from '../CartItem.vue'
import CartBoxItem from '../CartBoxItem.vue'
import HorizontalCartItem from '../HorizontalCartItem.vue'
import ReadOnlyCartItem from '../ReadOnlyCartItem.vue'
import HorizontalCartBoxItem from '../HorizontalCartBoxItem.vue'
import ReadOnlyCartBoxItem from '../ReadOnlyCartBoxItem.vue'
import OrdersHistory from '../OrdersHistory.vue'
import OrdersHistoryList from '../OrdersHistoryList.vue'
import OrdersHistoryDetail from '../OrdersHistoryDetail.vue'
import Slider from '../Slider.vue'
import Ribbon from '../Ribbon'
import ProductTemplate from '../ProductTemplate.vue'
import ProductFrequency from '../ProductFrequency'
import CartRecap from '../CartRecap'
import OrangeInit from '../OrangeInit'
import Payment from '../Payment'


Vue.use(TurbolinksAdapter)
Vue.use(VueRouter)
Vue.use(VueSweetalert2);
Vue.component('app', App)
Vue.component('date-picker', DatePicker)
Vue.component('ToggleButton', ToggleButton)
Vue.component('multiselect', Multiselect)
Vue.component('p-check', PrettyCheck);
Vue.component('VueSlickCarousel', VueSlickCarousel)

Vue.component('product-list', ProductList)
Vue.component('product-item', ProductItem)
Vue.component('product-detail', ProductDetail)

Vue.component('box-list', BoxList)
Vue.component('box-item', BoxItem)
Vue.component('box-detail', BoxDetail)
Vue.component('order-item-list', OrderItemList)
Vue.component('order-item', OrderItem)
Vue.component('glass-item', GlassItem)
Vue.component('order-box-item', OrderBoxItem)
Vue.component('ro-order-item-list', ReadOnlyOrderItemList)
Vue.component('ro-order-item', ReadOnlyOrderItem)
Vue.component('ro-order-box-item', ReadOnlyOrderBoxItem)
Vue.component('my-account', MyAccount)
Vue.component('delivery-settings', DeliverySettings)
Vue.component('account-settings', AccountSettings)
Vue.component('next-delivery', NextDelivery)
Vue.component('my-cart', MyCart)
Vue.component('feedback', Feedback)
Vue.component('notifications', Notification)
Vue.component('liked-products', LikedProducts)
Vue.component('faq', Faq)
Vue.component('product-filter', ProductFilter)
Vue.component('locate-me', LocateMe)
Vue.component('signup', Signup)
Vue.component('signup-complete', SignupComplete)
Vue.component('contact', Contact)
Vue.component('cart', Cart)
Vue.component('cart-item', CartItem)
Vue.component('cart-box-item', CartBoxItem)
Vue.component('h-cart-item', HorizontalCartItem)
Vue.component('ro-cart-item', ReadOnlyCartItem)
Vue.component('ro-cart-box-item', ReadOnlyCartBoxItem)
Vue.component('h-cart-box-item', HorizontalCartBoxItem)
Vue.component('orders-history', OrdersHistory)
Vue.component('orders-history-list', OrdersHistoryList)
Vue.component('orders-history-detail', OrdersHistoryDetail)
Vue.component('slider', Slider)
Vue.component('ribbon', Ribbon)
Vue.component('product-template', ProductTemplate)
Vue.component('product-frequency', ProductFrequency)
Vue.component('cart-recap', CartRecap)
Vue.component('orange-init', OrangeInit)
Vue.component('payment', Payment)

const routes = [
  { name: 'orders-history-list', path: '/orders-history', component: OrdersHistoryList },
  { name: 'orders-history-detail', path: '/orders-history/:orderId', component: OrdersHistoryDetail }
]

const router = new VueRouter({
  routes
})

document.addEventListener('turbolinks:load', () => {
  const app = new Vue({
    router,
    el: '[data-behavior="vue"]'
  })
});
